exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-services-computer-vision-js": () => import("./../../../src/pages/services/computer-vision.js" /* webpackChunkName: "component---src-pages-services-computer-vision-js" */),
  "component---src-pages-services-embedded-development-js": () => import("./../../../src/pages/services/embedded-development.js" /* webpackChunkName: "component---src-pages-services-embedded-development-js" */),
  "component---src-pages-services-nds-js": () => import("./../../../src/pages/services/nds.js" /* webpackChunkName: "component---src-pages-services-nds-js" */)
}

